<template>
    <v-layout flex align-center justify-center style="margin-top: 60px;">
        <v-flex xs12 sm4>
            <v-toolbar color="primary" style="color: white"
                       class="elevation-2">
                <v-toolbar-title>Форма заказа</v-toolbar-title>
            </v-toolbar>

            <v-card
                    class="d-flex flex-column fill-height elevation-2"
            >
                <v-card-title>
                    Заказ №
                </v-card-title>
                <v-card-text class="flex-grow-1 overflow-y-auto">
                    <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                    >
                        <v-text-field
                                v-model="name"
                                :counter="10"
                                :rules="nameRules"
                                label="Наименование товара"
                                required
                        ></v-text-field>
                        <v-text-field
                                v-model="name"
                                :counter="10"
                                :rules="nameRules"
                                label="Рекламируемый материал (ролик)"
                                required
                        ></v-text-field>
                        <v-text-field
                                v-model="name"
                                :counter="10"
                                :rules="nameRules"
                                label="Длительность показа рекламного материала"
                                required
                        ></v-text-field>
                        <v-text-field
                                v-model="name"
                                :counter="10"
                                :rules="nameRules"
                                label="Период размещения рекламной кампании"
                                required
                        ></v-text-field>
                        <v-textarea
                                v-model="name"
                                :counter="10"
                                :rules="nameRules"
                                label="Требования к рекламной кампании"
                                required
                        ></v-textarea>
                        <v-text-field
                                v-model="name"
                                :counter="10"
                                :rules="nameRules"
                                label="Бюджет рекламной кампании"
                                required
                        ></v-text-field>

                        <v-layout justify-space-between>
                            <v-spacer></v-spacer>
                            <v-btn color="primary">Сохранить заказ
                            </v-btn>
                        </v-layout>
                    </v-form>

                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    name: "Order",
    data() {
        return {
            name:'',
        }
    }
}
</script>

<style scoped>

</style>
