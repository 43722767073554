<template>
    <v-layout flex align-center justify-center style="margin-top: 60px;">
        <v-flex xs12 sm4>
            <v-toolbar color="primary" style="color: white"
                       class="elevation-2">
                <v-toolbar-title>Рекламные материалы заказчика</v-toolbar-title>
            </v-toolbar>

            <v-card
                    class="d-flex flex-column fill-height elevation-2"
            >
                <v-card-title>
                    Рекламные материалы
                </v-card-title>

                <v-card-text class="flex-grow-1 overflow-y-auto">
                    <v-text-field label="filter"></v-text-field>
                    <div style="display: flex; flex-wrap: wrap; justify-content: space-between;">

                        <div v-for="(v,idx) in videos" :key="idx">
                            <v-img
                                    style="margin: 16px 16px 16px 0;"
                                    lazy-src="https://picsum.photos/id/11/10/6"
                                    max-height="80"
                                    max-width="140"
                                    src="https://picsum.photos/id/11/500/300">
                            </v-img>
                            <p>img title</p>
                        </div>
                    </div>

                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    name: "VideosLib",
    data() {
        return {
            videos: [{}, {}, {}, {}, {}, {}]
        }
    }
}
</script>

<style scoped>

</style>
