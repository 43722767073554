<template>
    <v-container fluid fill-height class="loginOverlay">
        <v-layout flex align-center justify-center style="margin-top: 60px;">
            <v-flex xs12 sm4>
                <v-toolbar color="primary" style="color: white"
                           class="elevation-2">
                    <v-toolbar-title>Чат</v-toolbar-title>
                </v-toolbar>

                <v-card
                        class="d-flex flex-column fill-height elevation-2"
                >
                    <v-card-title>
                        Общение с клиентом
                    </v-card-title>
                    <v-card-text class="flex-grow-1 overflow-y-auto">
                        <v-avatar
                                color="primary"
                                size="56"
                        ></v-avatar>

                        <p style="margin-right: 30px;">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque
                            dolore
                            inventore, ipsam
                            nesciunt nobis quo sapiente veniam. Aliquam dolorum fuga ipsa iure perferendis reprehenderit
                            tenetur! Beatae blanditiis dolor laboriosam maiores.</p>

                        <v-avatar style="display: block; margin-left: auto;"
                                  color="secondary"
                                  size="56"
                        ></v-avatar>

                        <p style="margin-left: 30px;">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            Accusamus dicta ea
                            earum est et
                            fugit harum id, minus odio ratione rem rerum saepe vel! Dolor iste praesentium rem rerum
                            sunt?</p>
                        <v-avatar
                                color="primary"
                                size="56"
                        ></v-avatar>

                        <p style="margin-right: 30px;">Lorem ipsum dolor sit amet, consectetur adipisicing elit. Cumque
                            dolore
                            inventore, ipsam
                            nesciunt nobis quo sapiente veniam. Aliquam dolorum fuga ipsa iure perferendis reprehenderit
                            tenetur! Beatae blanditiis dolor laboriosam maiores.</p>

                        <v-avatar style="display: block; margin-left: auto;"
                                  color="secondary"
                                  size="56"
                        ></v-avatar>

                        <p style="margin-left: 30px;">Lorem ipsum dolor sit amet, consectetur adipisicing elit.
                            Accusamus dicta ea
                            earum est et
                            fugit harum id, minus odio ratione rem rerum saepe vel! Dolor iste praesentium rem rerum
                            sunt?</p>


                    </v-card-text>
                    <v-card-text class="flex-shrink-1">
                        <v-text-field/>
                    </v-card-text>
                </v-card>

            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
    name: "Chat"
}
</script>

<style scoped>

</style>
