<template>
    <v-layout flex align-center justify-center style="margin-top: 60px;">
        <v-flex xs12 sm4>
            <v-toolbar color="primary" style="color: white"
                       class="elevation-2">
                <v-toolbar-title>Аналитика заказа</v-toolbar-title>
            </v-toolbar>

            <v-card
                    class="d-flex flex-column fill-height elevation-2"
            >
                <v-card-title>

                </v-card-title>
                <v-card-text class="flex-grow-1 overflow-y-auto">

                    <v-dialog
                            v-model="dialog1"
                            fullscreen
                            hide-overlay
                            transition="dialog-bottom-transition"
                    >
                        <template v-slot:activator="{ on, attrs }">

                            <v-btn class="mb-2" color="info" v-bind="attrs"
                                   v-on="on" x-large block>Результаты аналитики</v-btn>
                        </template>
                        <v-card>
                            <v-toolbar
                                    dark
                                    color="primary"
                            >
                                <v-btn
                                        icon
                                        dark
                                        @click="dialog1 = false"
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>Результаты аналитики</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn
                                            dark
                                            text
                                            @click="dialog1 = false"
                                    >
                                        Save
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>

<!--                                <v-subheader></v-subheader>-->

                            <v-textarea rows="20" class="ma-10" value="Вот такие вот результаты аналитики..." outlined label="Результаты аналитики"></v-textarea>
                        </v-card>
                    </v-dialog>



                          <v-dialog
                            v-model="dialog2"
                            fullscreen
                            hide-overlay
                            transition="dialog-bottom-transition"
                    >
                        <template v-slot:activator="{ on, attrs }">

                            <v-btn class="mb-2" color="warning" v-bind="attrs"
                                   v-on="on" x-large block>Парсер аналитики</v-btn>
                        </template>
                        <v-card>
                            <v-toolbar
                                    dark
                                    color="warning"
                            >
                                <v-btn
                                        icon
                                        dark
                                        @click="dialog2 = false"
                                >
                                    <v-icon>mdi-close</v-icon>
                                </v-btn>
                                <v-toolbar-title>Парсер аналитики</v-toolbar-title>
                                <v-spacer></v-spacer>
                                <v-toolbar-items>
                                    <v-btn
                                            dark
                                            text
                                            @click="dialog2 = false"
                                    >
                                        Save
                                    </v-btn>
                                </v-toolbar-items>
                            </v-toolbar>

<!--                                <v-subheader></v-subheader>-->

                            <v-textarea rows="20" class="ma-10" value="Параметры парсера аналитики..." outlined label="Парсер аналитики"></v-textarea>
                        </v-card>
                    </v-dialog>






                    <v-btn class="mb-2" color="success" x-large block>Получить отчет</v-btn>

                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>

</template>

<script>
export default {
    name: "ListOfClients",
    data() {
        return {
            dialog1: false,
            dialog2: false,
            dialog3: false,
            notifications: false,
            sound: true,
            widgets: false,
        }
    }
}
</script>

<style scoped>

</style>
