<template>
    <v-layout flex align-center justify-center style="margin-top: 60px;">
        <v-flex xs12 sm4>
            <v-toolbar color="primary" style="color: white"
                       class="elevation-2">
                <v-toolbar-title>Клиенты / Заказы</v-toolbar-title>
            </v-toolbar>

            <v-card
                    class="d-flex flex-column fill-height elevation-2"
            >
                <v-card-title>

                </v-card-title>
                <v-card-text class="flex-grow-1 overflow-y-auto">


                    <v-tabs
                            v-model="tab"
                            background-color="transparent"
                            color="basil"
                            grow
                    >
                        <v-tab
                                v-for="item in items"
                                :key="item"
                        >
                            {{ item }}
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <v-card
                                    color="basil"
                                    flat
                            >
                                <v-card-text>

                                    <div v-for="(client, i) in clients" :key="i">
                                        <v-card class="mt-2 pa-4">
                                            {{ client.title }} - Редактировать
                                        </v-card>
                                    </div>

                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card
                                    color="basil"
                                    flat
                            >
                                <v-card-text>

                                    <div v-for="(order, i) in ordersArchive" :key="i">
                                        <v-card class="mt-2 pa-4" style="display: flex; flex-direction: row; justify-content: space-between">

                                            <p>{{ i }} -</p>
                                            <p>{{ order.title }} -</p>
                                            <p>{{order.status}} -</p>
                                                <p>Подробнее</p>
                                        </v-card>
                                    </div>

                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>


                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>

</template>

<script>
export default {
    name: "ListOfClients",
    data() {
        return {
            tab: null,
            clients: [{
                title: 'Mediacube',
                UNP: 123123123,
                rekvizits: 'р/с 982379876324862387687635 BIС YRY38EUX Приорбанк',
                address: 'РБ, г. Минск, ул. Пинская, д.28, оф. 1',
                country: 'Canada',
                phone: '+375 29 777 77 77',
                email: 'mediacube@gmail.com'
            },
                {
                    title: 'Клиент-2',
                }
            ],
            ordersArchive: [
                {title: 'Заказ', status: 'На рассмотрении'},
                {title: 'Заказ', status: 'На рассмотрении'},
                {title: 'Заказ', status: 'На одобрении'},
                {title: 'Заказ', status: 'На одобрении'},
                {title: 'Заказ', status: 'В производстве'},
                {title: 'Заказ', status: 'завершен'},
                {title: 'Заказ', status: 'завершен'},
            ],
            items: [
                'Клиенты', 'Заказы',
            ]
        }
    }
}
</script>

<style scoped>

</style>
