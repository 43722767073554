<template>
    <v-app>
        <v-app-bar
                app
                color="primary"
                dark
        >
            <div class="d-flex align-center">
                <v-img
                        alt="A4 Logo"
                        class="shrink mr-2"
                        contain
                        src="https://a4.by/img.png"
                        transition="scale-transition"
                        width="40"
                />

                4A4 Video Application
            </div>

            <v-spacer></v-spacer>

            <v-btn
                    href="https://a4.by"
                    target="_blank"
                    text
            >
                <span class="mr-2">Справка</span>
                <v-icon>mdi-open-in-new</v-icon>
            </v-btn>
        </v-app-bar>


        <main>
            <login></login>
        </main>

        <main>
            <chat></chat>
        </main>

        <main>
            <client-profile></client-profile>
        </main>

        <main>
            <client-screen></client-screen>
        </main>

        <main>
            <order></order>
        </main>

        <main>
            <videos-lib></videos-lib>
        </main>

        <main>
            <advert-materials></advert-materials>
        </main>

        <main>
            <a4info></a4info>
        </main>

        <main>
            <list-of-clients></list-of-clients>
        </main>

        <main>
            <order-details></order-details>
        </main>
        <main>
            <video-production></video-production>
        </main>
        <main>
            <analytics></analytics>
        </main>

        <v-divider class="mt-16 mb-16"></v-divider>
        <div class="mt-16 mb-16 text-sm-center">© ООО "ЧЕТЫРЕАЧЕТЫРЕ" 2020—2021</div>
    </v-app>
</template>

<script>
import Login from './components/Login';
import Chat from './components/Chat';
import ClientProfile from './components/ClientProfile';
import ClientScreen from './components/ClientScreen';
import Order from './components/Order';
import VideosLib from './components/VideosLib';
import AdvertMaterials from './components/AdvertMaterials';
import A4info from './components/A4info';
import ListOfClients from './components/ListOfClients';
import OrderDetails from './components/OrderDetails';
import VideoProduction from './components/VideoProduction';
import Analytics from './components/Analytics';

export default {
    name: 'App',

    components: {
        Login,
        Chat,
        ClientProfile,
        ClientScreen,
        Order,
        VideosLib,
        AdvertMaterials,
        A4info,
        ListOfClients,
        OrderDetails,
        VideoProduction,
        Analytics,
    },

    data: () => ({
        //
    }),
};
</script>
