<template>
    <v-layout flex align-center justify-center style="margin-top: 60px;">
        <v-flex xs12 sm4>
            <v-toolbar color="primary" style="color: white"
                       class="elevation-2">
                <v-toolbar-title>Ваши заказы</v-toolbar-title>
            </v-toolbar>

            <v-card
                    class="d-flex flex-column fill-height elevation-2"
            >
                <v-card-title>

                </v-card-title>

                <v-card-text class="flex-grow-1 overflow-y-auto">

                    <v-tabs
                            v-model="tab"
                            background-color="transparent"
                            color="basil"
                            grow
                    >
                        <v-tab
                                v-for="item in items"
                                :key="item"
                        >
                            {{ item }}
                        </v-tab>
                    </v-tabs>

                    <v-tabs-items v-model="tab">
                        <v-tab-item>
                            <v-card
                                    color="basil"
                                    flat
                            >
                                <v-card-text>

                                    <div v-for="(order, i) in orders" :key="i">
                                        <v-card>
                                            Заказ №{{ i }}
                                        </v-card>
                                    </div>

                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                        <v-tab-item>
                            <v-card
                                    color="basil"
                                    flat
                            >
                                <v-card-text>

                                    <div v-for="(order, i) in ordersArchive" :key="i">
                                        <v-card>
                                            Заказ №{{ i }}
                                        </v-card>
                                    </div>

                                </v-card-text>
                            </v-card>
                        </v-tab-item>
                    </v-tabs-items>

                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    name: "ClientScreen",
    data() {
        return {
            tab: null,
            orders: [{}, {}, {}, {}, {},],
            ordersArchive: [{}, {}, {}, {}, {}, {}, {}, {}, {},],
            items: [
                'Активные', 'Архив',
            ]
        }
    }
}
</script>

<style scoped>

</style>
