<template>
    <v-layout flex align-center justify-center style="margin-top: 60px;">
        <v-flex xs12 sm4>
            <v-toolbar color="primary" style="color: white;"
                       class="elevation-2">
                <v-toolbar-title>Производство ролика</v-toolbar-title>
            </v-toolbar>

            <v-card
                    class="d-flex flex-column fill-height elevation-2"
            >
                <v-card-title>

                </v-card-title>
                <v-card-text class="flex-grow-1 overflow-y-auto">

                    <div style="width: 100%; height: 80px; background-color: lightblue;">

                    </div>

                    <div style="display: flex; flex-direction: row; justify-content: space-between; margin-bottom: 30px;">
                        <div class="triagleUp"></div>
                        <div class="triagleUp"></div>
                        <div class="triagleUp"></div>
                        <div class="triagleUp"></div>
                    </div>


                    <div v-for="(time, idx) in [1,2,3,4]" :key="idx">
                        <v-text-field label="время:" :value="idx + ':0'+Math.trunc(Math.random()*10)" >
                        </v-text-field>
                    </div>


                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>

</template>

<script>
export default {
    name: "ListOfClients",
    data() {
        return {
            tab: null,
            clients: [{
                title: 'Mediacube',
                UNP: 123123123,
                rekvizits: 'р/с 982379876324862387687635 BIС YRY38EUX Приорбанк',
                address: 'РБ, г. Минск, ул. Пинская, д.28, оф. 1',
                country: 'Canada',
                phone: '+375 29 777 77 77',
                email: 'mediacube@gmail.com'
            },
                {
                    title: 'Клиент-2',
                }
            ],
            ordersArchive: [{title: 'Заказ'}, {title: 'Заказ'}, {title: 'Заказ'}, {title: 'Заказ'}, {title: 'Заказ'}, {title: 'Заказ'}, {title: 'Заказ'},],
            items: [
                'Клиенты', 'Заказы',
            ]
        }
    }
}
</script>

<style scoped>
.triagleUp {
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 10px 20px 10px;
    border-color: transparent transparent lightgrey transparent;
}
</style>
