<template>
    <v-layout flex align-center justify-center style="margin-top: 60px;">
        <v-flex xs12 sm4>
            <v-toolbar color="primary" style="color: white"
                       class="elevation-2">
                <v-toolbar-title>Использование рекламных материалов</v-toolbar-title>
            </v-toolbar>

            <v-card
                    class="d-flex flex-column fill-height elevation-2"
            >
                <v-card-title>
                    Использование рекламных материалов
                </v-card-title>
                <v-card-text class="flex-grow-1 overflow-y-auto">

                    <div v-for="(m, idx) in materials" :key="idx"
                         style="display: grid; grid-template-columns: 50% 3fr 1fr; padding: 8px; border: 1px solid gray; border-radius: 8px; margin-bottom: 12px; align-items: center">
                        <p><strong>{{ m.title }}</strong></p>
                        <p>{{ m.length }} секунд</p>
                        <v-btn x-small color="purple" text="white" @click="removeMat(idx)">Удалить</v-btn>
                    </div>

                    <div style="display: flex">

                        <v-btn color="primary" @click="addMaterial">Добавить</v-btn>
                        <v-spacer></v-spacer>
                        <v-btn color="warning">Сохранить изменения</v-btn>
                    </div>

                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    name: "AdvertMaterials",
    data() {
        return {
            materials: [
                {
                    title: 'Mat1',
                    length: 8,
                    preview: '',
                }, {
                    title: 'Mat2',
                    length: 12,
                    preview: '',
                }, {
                    title: 'Mat3',
                    length: 11,
                    preview: '',
                }, {
                    title: 'Mat4',
                    length: 10,
                    preview: '',
                },
            ],
        }
    },
    methods: {
        addMaterial() {
            this.materials.push({
                title: 'NewMat',
                length: 15,
                preview: '',
            })
        },
        removeMat(i) {
            this.materials.splice(i, 1);
        }
    }
}
</script>

<style scoped>

</style>
