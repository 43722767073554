<template>
    <v-container fluid fill-height class="loginOverlay">
        <v-layout flex align-center justify-center style="margin-top: 60px;">
            <v-flex xs12 sm4>
                <v-toolbar color="primary" style="color: white"
                           class="elevation-2">
                    <v-toolbar-title>Login into the A4 System</v-toolbar-title>
                </v-toolbar>
                <v-card                                 class="elevation-2">
                    <v-card-text class="pt-4">
                        <div>
                            <v-form
                                    ref="form"
                                    v-model="valid"
                                    lazy-validation
                            >
                                <v-text-field
                                        v-model="name"
                                        :counter="10"
                                        :rules="nameRules"
                                        label="email"
                                        required
                                ></v-text-field>

                                <v-text-field
                                        v-model="password"
                                        :rules="emailRules"
                                        label="password"
                                        required
                                ></v-text-field>

                                <v-layout justify-space-between>
                                    <v-spacer></v-spacer>
                                    <v-btn color="primary">Login
                                    </v-btn>
                                    <!--                                            <a href="">Forgot Password</a>-->
                                </v-layout>
                            </v-form>
                        </div>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
export default {
name: "Login"
}
</script>

<style scoped>

</style>
