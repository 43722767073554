<template>
    <v-layout flex align-center justify-center style="margin-top: 60px;">
        <v-flex xs12 sm4>
            <v-toolbar color="primary" style="color: white"
                       class="elevation-2">
                <v-toolbar-title>Профиль клиента</v-toolbar-title>
            </v-toolbar>

            <v-card
                    class="d-flex flex-column fill-height elevation-2"
            >
                <v-card-title>
                    Клиент
                </v-card-title>
                <v-card-text class="flex-grow-1 overflow-y-auto">


                    <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                    >
                        <v-text-field
                                v-model="name"
                                :counter="10"
                                :rules="nameRules"
                                label="Название"
                                required
                        ></v-text-field>

                        <v-text-field
                                v-model="name"
                                :counter="10"
                                :rules="nameRules"
                                label="Регистрационный номер"
                                required
                        ></v-text-field>
                        <v-text-field
                                v-model="name"
                                :counter="10"
                                :rules="nameRules"
                                label="Адрес регистрации"
                                required
                        ></v-text-field>
                        <v-text-field
                                v-model="name"
                                :counter="10"
                                :rules="nameRules"
                                label="Страна регистрации"
                                required
                        ></v-text-field>

                        <v-card>

                            <v-card-title>
                                ФИО отвественного человека:
                            </v-card-title>
                            <v-card-text class="flex-grow-1 overflow-y-auto">
                                <v-text-field
                                        v-model="name"
                                        :counter="10"
                                        :rules="nameRules"
                                        label="Фамилия"
                                        required
                                ></v-text-field>
                                <v-text-field
                                        v-model="name"
                                        :counter="10"
                                        :rules="nameRules"
                                        label="Имя"
                                        required
                                ></v-text-field>
                                <v-text-field
                                        v-model="name"
                                        :counter="10"
                                        :rules="nameRules"
                                        label="Отчество"
                                        required
                                ></v-text-field>
                            </v-card-text>
                        </v-card>

                        <v-layout justify-space-between>
                            <v-spacer></v-spacer>
                            <v-btn color="primary">Сохранить
                            </v-btn>
                        </v-layout>
                    </v-form>


                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
    name: "ClientProfile"
}
</script>

<style scoped>

</style>
