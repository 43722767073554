<template>
    <v-layout flex align-center justify-center style="margin-top: 60px;">
        <v-flex xs12 sm4>
            <v-toolbar color="primary" style="color: white"
                       class="elevation-2">
                <v-toolbar-title>A4info</v-toolbar-title>
            </v-toolbar>

            <v-card
                    class="d-flex flex-column fill-height elevation-2"
            >
                <v-card-title>
                    Форма о нас
                </v-card-title>
                <v-card-text class="flex-grow-1 overflow-y-auto">

                    <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                    >
                        <v-text-field
                                v-model="name"
                                :counter="10"
                                :rules="nameRules"
                                label="Название"
                                required
                        ></v-text-field>
                        <v-text-field
                                v-model="name"
                                :counter="10"
                                :rules="nameRules"
                                label="УНП"
                                required
                        ></v-text-field>
                        <v-textarea
                                v-model="name"
                                :counter="10"
                                :rules="nameRules"
                                label="реквизиты"
                                required
                        ></v-textarea>
                        <v-textarea
                                v-model="name"
                                :counter="10"
                                :rules="nameRules"
                                label="Адрес регистрации"
                                required
                        ></v-textarea>
                        <v-textarea
                                v-model="name"
                                :counter="10"
                                :rules="nameRules"
                                label="Страна"
                                required
                        ></v-textarea>
                        <v-text-field
                                v-model="name"
                                :counter="10"
                                :rules="nameRules"
                                label="Телефон"
                                required
                        ></v-text-field>
                        <v-text-field
                                v-model="name"
                                :counter="10"
                                :rules="nameRules"
                                label="E-mail"
                                required
                        ></v-text-field>

                        <v-layout justify-space-between>
                            <v-spacer></v-spacer>
                            <v-btn color="primary">Сохранить
                            </v-btn>
                            <!--                                            <a href="">Forgot Password</a>-->
                        </v-layout>
                    </v-form>

                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>
</template>

<script>
export default {
name: "A4info"
}
</script>

<style scoped>

</style>
