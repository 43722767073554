<template>
    <v-layout flex align-center justify-center style="margin-top: 60px;">
        <v-flex xs12 sm4>
            <v-toolbar color="primary" style="color: white"
                       class="elevation-2">
                <v-toolbar-title>Изменить статус заказа</v-toolbar-title>
            </v-toolbar>

            <v-card
                    class="d-flex flex-column fill-height elevation-2"
            >
                <v-card-title>

                </v-card-title>
                <v-card-text class="flex-grow-1 overflow-y-auto">

                    <h3>Заказ</h3>
                    <p>Заказ такой-то</p>
                    <p>Клиент такой-то</p>

                    <p>Номер такой-то</p>
                    <p>Дата такая-то</p>
                    <p>наименование рекламируемого товара (товаров)</p>
                    <p>рекламный материал (рекламные видеоролик), с указанием его продолжительности</p>
                    <p>период размещения рекламной кампании</p>
                    <p>требования к проведению рекламной кампании</p>
                    <p>бюджет рекламной кампании</p>


                    <h3>Статусы:</h3>
                    <ul>
                        <li>на рассмотрении</li>
                        <li>на одобрении</li>
                        <li>в производстве</li>
                        <li>завершен</li>
                    </ul>


                </v-card-text>
            </v-card>
        </v-flex>
    </v-layout>

</template>

<script>
export default {
    name: "ListOfClients",
    data() {
        return {
            tab: null,
            clients: [{
                title: 'Mediacube',
                UNP: 123123123,
                rekvizits: 'р/с 982379876324862387687635 BIС YRY38EUX Приорбанк',
                address: 'РБ, г. Минск, ул. Пинская, д.28, оф. 1',
                country: 'Canada',
                phone: '+375 29 777 77 77',
                email: 'mediacube@gmail.com'
            },
                {
                    title: 'Клиент-2',
                }
            ],
            ordersArchive: [{title: 'Заказ'}, {title: 'Заказ'}, {title: 'Заказ'}, {title: 'Заказ'}, {title: 'Заказ'}, {title: 'Заказ'}, {title: 'Заказ'},],
            items: [
                'Клиенты', 'Заказы',
            ]
        }
    }
}
</script>

<style scoped>

</style>
